import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import SVG from "react-inlinesvg";
import { Button } from "@material-ui/core";
import DownloadIcon from "@mui/icons-material/Download";

import styles from "./assets/styles.module.css";
import closeIcon from "./assets/close.svg";

const InstallPWA = (props) => {
  const { onBeforeInstall, onAppInstalled, onChoice, title, icon } = props;
  const [support, setSupport] = useState(false);
  const [prompt, setPrompt] = useState(null);
  const [isIOS, setIOS] = useState(false);
  const [hide, setHide] = useState(false);
  const { t } = useTranslation();

  const checkIOS = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  const isInStandaloneMode = () =>
    "standalone" in window.navigator && window.navigator.standalone;

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      setPrompt(e);
      setSupport(true);

      if (onBeforeInstall) {
        onBeforeInstall(e);
      }
    });

    window.addEventListener("appinstalled", onAppInstalled);
    // TODO: online and offline
    // window.addEventListener('online', ...)
    // window.addEventListener('offline', ...)

    if (checkIOS()) {
      setIOS(true);

      if (isInStandaloneMode()) {
        setHide(true);
      }
    }

    // TODO: online and offline
    // return () => {
    //   window.removeEventListener('online', ...)
    //   window.removeEventListener('offline', ...)
    // }
  }, [onAppInstalled, onBeforeInstall]);

  const onClick = useCallback(() => {
    if (prompt) {
      prompt.prompt();
      prompt.userChoice.then((e) => {
        if (e.outcome === "accepted") {
          setPrompt(false);
        }

        if (onChoice) {
          onChoice(e);
        }
      });
    }
  }, [prompt, setPrompt, onChoice]);

  if (!support) {
    return null;
  }

  return (
    <>
      {icon ? (
        <div className="notificationBlock" style={{ marginLeft: 0 }}>
          <Button className="dropBtn anim">
            <DownloadIcon onClick={!isIOS && onClick} />
          </Button>
        </div>
      ) : (
        !hide &&
        prompt && (
          <div
            className={cx(styles.container, "notification")}
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "right",
            }}
          >
            <div className={styles.description}>
              {isIOS ? (
                // <>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    textAlign: "right",
                  }}
                >
                  {"להתקנת האפליקציה באייפון שלך"}
                  <div>
                    {"לחץ"}
                    <DownloadIcon sx={{ height: "20px", margin: "0 5px" }} />
                    {/* <img className={styles.iosButton} alt="" src={iosButton} /> */}
                    {"ואז הוסף למסך הבית."}
                  </div>
                </div>
              ) : (
                // </>
                <>{"למען נוחות, הורידו את האפליקציה שלנו בקליק אחד"}</>
              )}
            </div>
            {!isIOS && (
              <Button
                primary
                style={{
                  height: "28px",
                  padding: "0 15px",
                  lineHeight: 1,
                  fontSize: "15px",
                }}
                onClick={onClick}
              >
                {t(title)}
              </Button>
            )}
            <SVG
              className={styles.close}
              src={closeIcon}
              onClick={() => setHide(true)}
            />
          </div>
        )
      )}
    </>
  );
};

InstallPWA.propTypes = {
  onBeforeInstall: PropTypes.func,
  onAppInstalled: PropTypes.func,
  onChoice: PropTypes.func,
  title: PropTypes.string,
};

InstallPWA.defaultProps = {
  onBeforeInstall: (e) => {},
  onAppInstalled: (e) => {},
  onChoice: (e) => {},
  title: "התקן",
};

export default InstallPWA;
