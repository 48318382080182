import React from "react";
import { useParams } from "react-router-dom";

import Header from "../../header/Header";

import "./styles.css";

const Index = () => {
  const { orderId } = useParams();
  return (
    <div className="pageTemplate">
      <Header isFull={false} />
      <div className="container">
        <div className="pelecard-page__order pelecard__order_error">
          <h3 className="pelecard-page__title">
            Some problems with your order
          </h3>
          <div className="pelecard-page__info">Order order Id: {orderId}</div>
        </div>
      </div>
    </div>
  );
};

export default Index;
