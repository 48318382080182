export const CartActionTypes = {
  GET: {
    CART_COUNT_SUCCESS: "GET_CART_COUNT_SUCCESS",
    CART_PENDING: "GET_CART_PENDING",
    CART_SUCCESS: "GET_CART_SUCCESS",
    CART_ERROR: "GET_CART_ERROR",
  },
  POST: {
    CHECKOUT_PENDING: "POST_CHECKOUT_PENDING",
    CHECKOUT_SUCCESS: "POST_CHECKOUT_SUCCESS",
    CHECKOUT_ERROR: "POST_CHECKOUT_ERROR",

    FAST_CHECKOUT_PENDING: "POST_CHECKOUT_PENDING",
    FAST_CHECKOUT_SUCCESS: "POST_CHECKOUT_SUCCESS",
    FAST_CHECKOUT_ERROR: "POST_CHECKOUT_ERROR",
  },
};
