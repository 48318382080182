import React, { useEffect } from "react";
import { Snackbar, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import * as serviceWorker from "../../serviceWorker";

const ServiceWorkerWrapper = (props) => {
  const { t } = useTranslation();

  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState(null);

  useEffect(() => {
    serviceWorker.register({
      onUpdate: (registration) => {
        setShowReload(true);
        setWaitingWorker(registration.waiting);
      },
    });
  }, []);

  const reloadPage = () => {
    if (waitingWorker) {
      waitingWorker.postMessage({ type: "SKIP_WAITING" });
    }
    setShowReload(false);
    window.location.reload(true);
  };

  return (
    <Snackbar
      open={showReload}
      message={t("A new version is available!")}
      onClick={reloadPage}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      action={
        <Button color="inherit" size="small" onClick={reloadPage}>
          {t("Reload")}
        </Button>
      }
    />
  );
};

export default connect((state) => ({ user: state.user }))(ServiceWorkerWrapper);
