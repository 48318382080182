const getInstance = (beforeCallback, failCallback) => {
  if (window?.B24Chat?.instance) {
    if (typeof beforeCallback === "function") {
      beforeCallback();
    }

    return window.B24Chat.instance;
  }

  if (typeof failCallback === "function") {
    failCallback();
  }

  return false;
};

const login = (beforeCallback, failCallback) => {
  if (getInstance(beforeCallback, failCallback)) {
    getInstance().login();
  }
};

const register = (beforeCallback, failCallback) => {
  if (getInstance(beforeCallback, failCallback)) {
    getInstance().register();
  }
};

const open = (beforeCallback, failCallback) => {
  if (getInstance(beforeCallback, failCallback)) {
    getInstance().toggleSVG(0);
  }
};

const openMenu = (beforeCallback, failCallback) => {
  if (getInstance(beforeCallback, failCallback)) {
    getInstance().openMenu();
  }
};

const openProfile = (beforeCallback, failCallback) => {
  if (getInstance(beforeCallback, failCallback)) {
    getInstance().openProfile();
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  register,
  open,
  openMenu,
  openProfile,
};
