export const UserActionTypes = {
  GET_USER_PENDING: "GET_USER_PENDING",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_ERROR: "GET_USER_ERROR",

  LOGIN: {
    USER_VIA_OTP_PENDING: "LOGIN_USER_VIA_OTP_PENDING",
    USER_VIA_OTP_SUCCESS: "LOGIN_USER_VIA_OTP_SUCCESS",
    USER_VIA_OTP_ERROR: "LOGIN_USER_VIA_OTP_ERROR",
  },

  POST_MONEY_PENDING: "POST_MONEY_PENDING",
  POST_MONEY_SUCCESS: "POST_MONEY_SUCCESS",
  POST_MONEY_ERROR: "POST_MONEY_ERROR",

  SEND_MONEY_PENDING: "SEND_MONEY_PENDING",
  SEND_MONEY_SUCCESS: "SEND_MONEY_SUCCESS",
  SEND_MONEY_ERROR: "SEND_MONEY_ERROR",

  SEND_GIFT_PENDING: "SEND_GIFT_PENDING",
  SEND_GIFT_SUCCESS: "SEND_GIFT_SUCCESS",
  SEND_GIFT_ERROR: "SEND_GIFT_ERROR",

  USER_REGISTRATION_PENDING: "USER_REGISTRATION_PENDING",
  USER_REGISTRATION_SUCCESS: "USER_REGISTRATION_SUCCESS",
  USER_REGISTRATION_ERROR: "USER_REGISTRATION_ERROR",
};
