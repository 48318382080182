export const AuthActionTypes = {
  CHECK_TOKEN: "CHECK_TOKEN",
  TOKEN_EXPIRED: "TOKEN_EXPIRED",
  LOG_IN_PENDING: "LOG_IN_PENDING",
  LOG_IN: "LOG_IN",
  LOG_IN_ERROR: "LOG_IN_ERROR",
  LOG_OUT: "LOG_OUT",
  CREATE_USER: "CREATE_USER",
  CREATE_USER_ERROR: "CREATE_USER_ERROR",
  OAUTH: {
    LOGIN_WITH_OAUTH_PENDING: "LOGIN_WITH_OAUTH_PENDING",
    LOGIN_WITH_OAUTH_SUCCESS: "LOGIN_WITH_OAUTH_SUCCESS",
    LOGIN_WITH_OAUTH_ERROR: "LOGIN_WITH_OAUTH_ERROR",
  },
  RESET_PASSWORD_PENDING: "RESET_PASSWORD_PENDING",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",
  RESET_PASSWORD_CONFIRM_SUCCESS: "RESET_PASSWORD_CONFIRM_SUCCESS",
  RESET_PASSWORD_CONFIRM_ERROR: "RESET_PASSWORD_CONFIRM_ERROR",
};
