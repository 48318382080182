import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IconButton from "@mui/material/IconButton";

import Header from "../header/Header";
import { getProductById, openSidebar } from "../../redux/actions-exporter";
import QuantitySelector from "../quantitySelector";
import widgetHelper from "../../helpers/widget";
import CloseIcon from "@mui/icons-material/Close";

import "../category/category.css";

export default function ProductDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = location?.state;
  const product = useSelector((state) => state.product.product);
  const [numbersOfItems, setNumbersOfItems] = useState(1);
  const [userPrice, setUserPrice] = useState();
  const [merchants, setMerchants] = useState([]);
  const userId = useSelector((state) => state?.user?.user?.id);
  const [chain, setChain] = useState([]);

  useEffect(() => {
    dispatch(getProductById(id));
  }, [id]); //eslint-disable-line

  useEffect(() => {
    const list = [];
    product?.merchants?.map((merchant) => {
      if (
        !list.includes(merchant) &&
        !merchant.title.includes("סגור") &&
        !merchant.title.includes("איטלקיה") &&
        merchant.type !== "hr"
      )
        list.push(merchant);
    });

    const groups = Object.values(
      list.reduce((acc, curr) => {
        const firstWord = curr.network?.name || curr.title;
        if (!acc[firstWord]) {
          acc[firstWord] = [];
        }

        acc[firstWord].push(curr);
        return acc;
      }, {})
    );
    setMerchants(groups.sort((a, b) => b.length - a.length));
  }, [product]);

  const handleChangeQuantity = (e) => setNumbersOfItems(e.target.value);

  const handleAddProduct = (product) => {
    if (!userId) {
      widgetHelper.login(
        () => dispatch(openSidebar()),
        () => navigate("/login")
      );
    }
    // return navigate("/login");
    // dispatch(addToCart(product?.id, numbersOfItems, product?.bonusRuleId));
    navigate("/checkout", {
      state: {
        productId: id,
        amount: userPrice || product.price,
        discount: 0,
        type: "product",
        successURL: process.env.REACT_APP_API_PELECARD_LOADING_URL,
        errorURL: process.env.REACT_APP_API_PELECARD_LOADING_URL,
      },
    });
    // toast.success("The product has been successfully added");
  };

  useEffect(() => {
    if (product && product?.type === "gift") {
      navigate("/gift", {
        state: { product },
      });
    }
  }, [product]); //eslint-disable-line
  console.log(merchants);
  return (
    <div>
      <Header isProduct={true} />
      <div className="productDetails">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="productDetails-info">
                <div className="productDetails-heading">
                  <h5 className="productDetails-title">{product.title}</h5>
                  <h6 className="productDetails-price">
                    {/* {product?.oldPrice && (
                      <>
                        <s>₪{product.oldPrice}</s>&nbsp;
                      </>
                    )} */}
                    {product?.price > 0 ? (
                      <span>₪ {product.price}</span>
                    ) : (
                      <>
                        <input
                          type="number"
                          placeholder="סכום..."
                          value={userPrice}
                          onChange={(e) => setUserPrice(e.target.value)}
                          className="priceInput"
                        />{" "}
                        ₪
                      </>
                    )}
                    {/* {product?.credit && (
                      <>
                        {" "}
                        + {product.credit} <GiTwoCoins />
                      </>
                    )} */}
                  </h6>
                </div>
                {/* <div
                  onClick={() => {
                    navigate(`/vendor/${product.merchant.id}`, {
                      state: { id: product.merchant.id },
                    });
                  }}
                  className="productDetails-brand"
                >
                  {product?.merchant?.image && (
                    <span className="productDetails-brandImage">
                      <img
                        src={product.merchant.image}
                        alt=""
                        height={20}
                        width={20}
                        className="img-fluid"
                      />
                    </span>
                  )}
                  <p className="productDetails-brandName">
                    {product?.merchant?.title || t("No merchant")}
                  </p>
                </div> */}

                <QuantitySelector
                  onChange={handleChangeQuantity}
                  minValue={1}
                  maxValue={5}
                  value={numbersOfItems}
                />
                <div className="productDetails-btns">
                  <Button
                    className="addcart_btn"
                    onClick={() => handleAddProduct(product)}
                  >
                    <span>{t("addToCart")}</span>
                  </Button>
                  {/*<Button className="buynow_btn">{t("BuyNow")}</Button>*/}
                </div>
                {merchants?.length > 0 ? (
                  <>
                    <p className="productDetails-brandName">
                      {/* {t("merchants")} */}
                      חנויות מכבדות
                    </p>
                    <ul className="categoryList">
                      {merchants
                        // .filter((merchant) => merchant.type !== "hr")
                        // .sort((a, b) => (a.title > b.title ? 1 : -1))
                        .map((item) => (
                          <>
                            {/* <li
                              className="categoryList__block"
                              onClick={() => {
                                navigate(`/vendor/${item.id}`, {
                                  state: { id: item.id },
                                });
                              }}
                          
                            >
                              <div className="category-box__img">
                                <img
                                  src={item.image}
                                  className="img-fluid"
                                  alt="My Awesome"
                                />
                              </div>
                              <h6 className="category-box__title">
                                {item.title}
                              </h6>
                            </li> */}
                            <li
                              className="categoryList__block"
                              onClick={() => {
                                if (item.length === 1)
                                  navigate(`/vendor/${item.id}`, {
                                    state: { id: item.id },
                                  });
                              }}
                            >
                              <div className="category-box__img">
                                <img
                                  src={item[0].image}
                                  className="img-fluid"
                                  alt="My Awesome"
                                />
                              </div>
                              {item.length > 1 ? (
                                <>
                                  <h6 className="category-box__title up">
                                    {item[0].network?.name}
                                  </h6>
                                  <h6
                                    className="category-box__title down"
                                    onClick={() => setChain(item)}
                                  >
                                    {item.length > 1 && (
                                      <>
                                        סניפים
                                        <ArrowDropDownIcon />
                                      </>
                                    )}
                                  </h6>
                                </>
                              ) : (
                                <h6 className="category-box__title">
                                  {item[0].title.length < 20
                                    ? item[0].title
                                    : item[0].title.slice(0, 19) + "..."}
                                </h6>
                              )}

                              {chain === item ? (
                                <div className="chains">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      fontSize: "larger",
                                      fontWeight: "bold",
                                      padding: "10px 0",
                                    }}
                                  >
                                    {"סניפי "}
                                    {chain[0].network.name}
                                    <IconButton
                                      sx={{
                                        justifyContent: "end",
                                        p: 0,
                                      }}
                                      onClick={() => setChain([])}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </div>
                                  <div
                                    style={{
                                      overflowY: "scroll",
                                    }}
                                  >
                                    {chain?.map((branch, i) => (
                                      <>
                                        {i !== 0 && (
                                          <hr
                                            style={{
                                              margin: "5px",
                                              borderColor: "#eee",
                                            }}
                                          />
                                        )}
                                        <div
                                          className="chain"
                                          onClick={() => {
                                            navigate(`/vendor/${branch.id}`, {
                                              state: { id: branch.id },
                                            });
                                          }}
                                        >
                                          {branch.title}
                                        </div>
                                      </>
                                    ))}
                                  </div>
                                </div>
                              ) : null}
                            </li>
                          </>
                        ))}
                    </ul>
                  </>
                ) : (
                  t("No merchants")
                )}
                <div className="productDetails-content">
                  <h6 className="productDetails-contentTitle">
                    {t("description")}
                  </h6>
                  <p className="productDetails-contentText">
                    {product?.content || ""}
                  </p>
                </div>

                <ul className="productDetails-List">
                  {product?.sku && (
                    <li className="productDetails-ListItem">
                      <strong>{t("SKU")}: </strong>
                      {product.sku}
                    </li>
                  )}
                  <li className="productDetails-ListItem">
                    <strong>{t("categories")}: </strong>
                    {product?.categories?.reduce(
                      (p, c, i) => p + (i > 0 ? ", " : "") + c.title,
                      ""
                    ) || t("No categories")}
                  </li>
                  {/*<li className="productDetails-ListItem">*/}
                  {/*  <strong>{t("tags")}: </strong> {t("laptop")}*/}
                  {/*</li>*/}
                </ul>
              </div>
            </div>
            {/* <div className="col-lg-5 offset-lg-1"> */}
            <div className="productDetails-img">
              {product?.discount && (
                <div className="product-box__discount single">
                  <span className="product-box__off">
                    {product.discountType ? "" : "-"}
                    {product.discount}
                    {product.discountType}
                  </span>
                </div>
              )}
              <img
                src={product?.image}
                alt=""
                // height={50}
                // width={50}
                className="img-fluid"
              />
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
