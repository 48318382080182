import { MerchantsActionTypes } from "./merchant.types";
import { axiosNoTokenInstance } from "../../axios.config";

export const getMerchants =
  (benefitType, domain = process.env.REACT_APP_DOMAIN) =>
  (dispatch) => {
    dispatch({ type: MerchantsActionTypes.GET.GET_MERCHANT_PENDING });
    axiosNoTokenInstance
      .get(
        `/merchant?domain=${domain}${
          benefitType ? "&benefitType=" + benefitType + "" : ""
        }`
      )
      .then((res) =>
        dispatch({
          type: MerchantsActionTypes.GET.GET_MERCHANT_SUCCESS,
          payload: res.data,
        })
      )
      .catch((error) =>
        dispatch({
          type: MerchantsActionTypes.GET.GET_MERCHANT_ERROR,
          payload: error.response.data,
        })
      );
  };

export const getMerchantById =
  (merchantId, domain = process.env.REACT_APP_DOMAIN) =>
  (dispatch) => {
    dispatch({ type: MerchantsActionTypes.GET.GET_MERCHANT_BY_ID_PENDING });

    axiosNoTokenInstance
      .get(`/merchant/${merchantId}?domain=${domain}`)
      .then((res) =>
        dispatch({
          type: MerchantsActionTypes.GET.GET_MERCHANT_BY_ID_SUCCESS,
          payload: res.data,
        })
      )
      .catch((error) =>
        dispatch({
          type: MerchantsActionTypes.GET.GET_MERCHANT_BY_ID_ERROR,
          payload: error.response.data,
        })
      );
  };

export const getMerchantCategories = (merchantId) => (dispatch) => {
  dispatch({
    type: MerchantsActionTypes.GET.GET_MERCHANT_BY_CATEGORY_ID_PENDING,
  });

  axiosNoTokenInstance
    .get(`/merchant/${merchantId}/categories`)
    .then((res) =>
      dispatch({
        type: MerchantsActionTypes.GET.GET_MERCHANT_BY_CATEGORY_ID_SUCCESS,
        payload: res.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: MerchantsActionTypes.GET.GET_MERCHANT_BY_CATEGORY_ID_ERROR,
        payload: error.response.data,
      })
    );
};

export const getMerchantProducts =
  (merchantId, domain = process.env.REACT_APP_DOMAIN) =>
  (dispatch) => {
    dispatch({ type: MerchantsActionTypes.GET.GET_MERCHANT_PRODUCTS_PENDING });

    axiosNoTokenInstance
      .get(`/merchant/${merchantId}/products?domain=${domain}`)
      .then((res) =>
        dispatch({
          type: MerchantsActionTypes.GET.GET_MERCHANT_PRODUCTS_SUCCESS,
          payload: res.data,
        })
      )
      .catch((error) =>
        dispatch({
          type: MerchantsActionTypes.GET.GET_MERCHANT_PRODUCTS_ERROR,
          payload: error.response.data,
        })
      );
  };

export const getNetworks =
  (domain = process.env.REACT_APP_DOMAIN) =>
  (dispatch) => {
    dispatch({ type: MerchantsActionTypes.GET.GET_NETWORKS_PENDING });
    axiosNoTokenInstance
      .get(
        `/network?getMerchants=true&merchantDomain=${domain}&domain=${domain}`
      )
      .then((res) => {
        dispatch({
          type: MerchantsActionTypes.GET.GET_NETWORKS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((error) =>
        dispatch({
          type: MerchantsActionTypes.GET.GET_NETWORKS_ERROR,
          payload: error.response.data,
        })
      );
  };
