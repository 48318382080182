import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Header from "../header/Header";
import { getOrder, clearOrder } from "../../redux/actions-exporter";

import "../cart/pay.css";

function Pay() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const paymentURL = location?.state?.paymentURL;
  const orderId = location?.state?.orderId;
  let orderCheckTimer;
  const order = useSelector((state) => state.order.order);

  useEffect(() => {
    if (!paymentURL) {
      navigate("/");
    }
  }, [paymentURL]); // eslint-disable-line

  useEffect(() => {
    if (orderId) {
      orderCheckTimer = setInterval(() => dispatch(getOrder(orderId)), 5000); // eslint-disable-line
    }
  }, [orderId]); // eslint-disable-line

  useEffect(() => () => clearInterval(orderCheckTimer), []); // eslint-disable-line

  useEffect(() => {
    if (order?.status === "complete" && order?.metadata?.cardNumber) {
      navigate("/paymentapprove");
    } else if (order?.status === "canceled") {
      dispatch(clearOrder());
      navigate(`/checkout/error/${orderId}`);
    }
  }, [order]); // eslint-disable-line

  return (
    <div>
      <Header isFull={false} />
      <div className="pageTemplate">
        <div className="container">
          <iframe
            title="payment"
            className="checkout__payment"
            src={paymentURL}
          />
        </div>
      </div>
    </div>
  );
}

export default Pay;
