import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useDispatch } from "react-redux";
import OtpInput from "react-otp-input";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import {
  loginViaOtp,
  validationCode,
  setSidebarState,
} from "../../redux/actions-exporter";
import autoReadSMS from "../../helpers/autoReadSMS";

const EXPIRED_OTP_TIME = 60; //sec
const OTP_LENGTH = 4; //chars
const theme = createTheme({
  direction: "rtl",
});
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export default function SignIn() {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");
  const [showValidationCode, setShowValidationCode] = useState(false);

  const handleChangePhone = (event) => setPhone(event.target.value);
  const handleValidateOTP = (phone, otp) => {
    dispatch(
      validationCode(
        phone,
        otp,
        () => {
          setOtp(null);
          dispatch(setSidebarState(false));
        },
        (message) => {
          if (message === "Your code has expired") {
            setOtp(null);
            setShowValidationCode(false);
          }
        }
      )
    );
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formPhone = data.get("identifier");

    if (otp) {
      handleValidateOTP(formPhone, otp);
    } else {
      if (!showValidationCode) {
        autoReadSMS(setOtp, EXPIRED_OTP_TIME);
        setShowValidationCode(true);
        dispatch(
          loginViaOtp(
            {
              phone: formPhone,
              host: process.env.REACT_APP_API_URL_HOST,
            },
            () => {
              setShowValidationCode(true);
            }
          )
        );
      }
    }
  };

  useEffect(() => {
    if (otp && otp.length === OTP_LENGTH) handleValidateOTP(phone, otp);
  }, [otp]);

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                m: 1,
                bgcolor: "var(--secondary)",
                width: 77,
                height: 77,
              }}
            >
              <LockOutlinedIcon fontSize="large" />
            </Avatar>
            <Typography component="h1" variant="h5">
              אימות חשבון
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              // noValidate
              sx={{ textAlign: "left", mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="identifier"
                label="טלפון"
                name="identifier"
                value={phone}
                onChange={handleChangePhone}
                autoComplete="identifier"
                autoFocus
                sx={{
                  "& label.Mui-focused": {
                    color: "var(--bg)",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "var(--bg)",
                    },
                  },
                }}
              />

              {showValidationCode ? (
                <>
                  <Button
                    disabled
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 1 }}
                  >
                    נא להזין את קוד האימות
                  </Button>
                  {/* <Link
                    href="#"
                  >
                    שליחה מחדש
                  </Link> */}
                  <OtpInput
                    // isDisabled={end}
                    autocomplete="one-time-code"
                    shouldAutoFocus
                    placeholder="****"
                    value={otp}
                    onChange={(e) => {
                      setOtp(e);
                    }}
                    isInputNum={true}
                    numInputs={4}
                    separator={<span> </span>}
                    containerStyle={{
                      width: "80%",
                      justifyContent: "space-evenly",
                      margin: "auto",
                      marginTop: "20px",
                      direction: "ltr",
                    }}
                    inputStyle={{
                      width: "50%",
                      margin: "auto",
                      height: "50px",
                    }}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 4,
                      background: "var(--primary)",
                      "&:focus": {
                        background: "var(--primary)",
                      },
                    }}
                  >
                    שליחה
                  </Button>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      padding: "20px",
                      fontSize: "33px",
                    }}
                  >
                    <CountdownCircleTimer
                      isPlaying
                      // key={key}
                      duration={EXPIRED_OTP_TIME}
                      colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                      colorsTime={[60, 45, 30, 15]}
                      size={120}
                      onComplete={() => {
                        setShowValidationCode(false);
                        setOtp(null);
                      }}
                    >
                      {({ remainingTime }) => remainingTime}
                    </CountdownCircleTimer>
                  </div>
                </>
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 4,
                    background: "var(--primary)",
                    "&:focus": {
                      background: "var(--primary)",
                    },
                  }}
                >
                  לקבלת קוד אימות
                </Button>
              )}
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </CacheProvider>
  );
}
