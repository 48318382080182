import * as React from "react";
import { useDispatch } from "react-redux";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import { logout, setSidebarState } from "../../redux/actions-exporter";
import {
  Box,
  Grid,
  Link,
  Divider,
  Container,
  Paper,
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from "@mui/material";

import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LogoutIcon from "@mui/icons-material/Logout";
import SecurityIcon from "@mui/icons-material/Security";
import BlockIcon from "@mui/icons-material/Block";
import GavelIcon from "@mui/icons-material/Gavel";
// import { GavelIcon } from '@mui/icons-material/GavelIcon'

const theme = createTheme({
  direction: "rtl",
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export default function Menu() {
  const arr = [
    { icon: <LightbulbIcon />, title: "אודות", text: "" },
    { icon: <GavelIcon />, title: "תקנון", text: "" },
    { icon: <SecurityIcon />, title: "רכישה מאובטחת", text: "" },
    { icon: <BlockIcon />, title: "מדיניות ביטולים", text: "" },
  ];
  const dispatch = useDispatch();

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          {/* <Box
            sx={{
              marginTop: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
          </Box> */}
          <h1
            style={{
              color: "var(--primary)",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            +Wincard
          </h1>
          <MenuList
            sx={{
              "& .MuiListItemText-root": { textAlign: "start !important" },
            }}
          >
            {arr.map((item) => (
              <MenuItem sx={{ my: 1 }}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText>{item.title}</ListItemText>
              </MenuItem>
            ))}

            <Divider />
            <MenuItem
              onClick={() => {
                dispatch(logout());
                dispatch(setSidebarState(false));
              }}
            >
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>התנתקות</ListItemText>
            </MenuItem>
          </MenuList>
        </Container>
      </ThemeProvider>
    </CacheProvider>
  );
}
