import { CartActionTypes } from "./cart.types";

const INITIAL_STATE = {
  isCartPending: false,
  cart: [],
  count: 0,
  cartError: null,

  isCheckoutPending: false,
  checkoutError: null,

  isFastCheckoutPending: false,
  fastCheckout: null,
  fastCheckoutError: null,
};

const CartReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case CartActionTypes.GET.CART_COUNT_SUCCESS:
      return {
        ...state,
        count: action.payload,
      };

    case CartActionTypes.GET.CART_PENDING:
      return {
        ...state,
        isCartPending: true,
      };

    case CartActionTypes.GET.CART_SUCCESS:
      return {
        ...state,
        isCartPending: false,
        cart: action.payload,
        cartError: null,
      };

    case CartActionTypes.GET.CART_ERROR:
      return {
        ...state,
        isCartPending: false,
        cartError: action.payload,
      };

    case CartActionTypes.POST.CHECKOUT_PENDING:
      return {
        ...state,
        isCheckoutPending: true,
      };

    case CartActionTypes.POST.CHECKOUT_SUCCESS:
      return {
        ...state,
        isCheckoutPending: false,
        cart: action.payload,
        checkoutError: null,
      };

    case CartActionTypes.POST.CHECKOUT_ERROR:
      return {
        ...state,
        isCheckoutPending: false,
        checkoutError: action.payload,
      };

    case CartActionTypes.POST.FAST_CHECKOUT_PENDING:
      return {
        ...state,
        isFastCheckoutPending: true,
        fastCheckout: null,
      };

    case CartActionTypes.POST.FAST_CHECKOUT_SUCCESS:
      return {
        ...state,
        isFastCheckoutPending: false,
        fastCheckout: action.payload,
        fastCheckoutError: null,
      };

    case CartActionTypes.POST.FAST_CHECKOUT_ERROR:
      return {
        ...state,
        isFastCheckoutPending: false,
        fastCheckoutError: action.payload,
      };

    default:
      return state;
  }
};

export default CartReducer;
