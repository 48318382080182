import React, { useEffect, useState } from "react";
import { FiHome } from "react-icons/fi";
import { AiOutlineTags } from "react-icons/ai";
import { BsWallet2 } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import cx from "classnames";
import { BiSupport } from "react-icons/bi";
import { AiFillPhone } from "react-icons/ai";
import { BsFillChatFill } from "react-icons/bs";
import Drawer from "@mui/material/Drawer";

import GrayIcon from "./GrayIcon";
import SignUp from "../sign/SignUp";
import SignIn from "../sign/SignIn";
import Menu from "../sign/Menu";
import ForgotPassword from "../sign/ForgotPassword";
import { setSidebarState, openSidebar } from "../../redux/actions-exporter";
import widgetHelper from "../../helpers/widget";

import "./Footer.css";

const containerArr = [
  {
    img: <FiHome />,
    link: "/",
    title: "ראשי",
  },
  {
    img: <AiOutlineTags />,
    link: "/unusedbenefits",
    title: "טרם מומשו",
  },
  // {
  //   img: <DinnerDiningOutlinedIcon />,
  //   link: "",
  //   title: "מסעדות",
  // },
  {
    img: <BsWallet2 />,
    link: "/wallet",
    title: "ארנק",
  },
];

const Footer = ({ setPhone }) => {
  const dispatch = useDispatch();
  const isOpenSidebar = useSelector((state) => state.sidebar.isOpen);
  const user = useSelector((state) => state.user.user);
  const [support, setSupport] = useState(false);
  const [signUp, setSignUp] = useState("signin");
  useEffect(() => {
    setPhone(support);
    // console.log(support);
  }, [support]);
  // const [url, setUrl] = useState(window.location.pathname);
  // console.log(user);
  return (
    <>
      {/* <BrowserView>
        <div className="webFooter">
          <div className="customizer border-left-blue-grey border-left-lighten-4">
            <div className="customizer-toggle box-shadow-3">
              {containerArr.map((el, i) => {
                return (
                  <Link to={el.link} key={i}>
                    {el.img}
                  </Link>
                );
              })}

              <Link to="#">
                <IoIosChatbubbles />
              </Link>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView> */}

      <div>
        <footer className="footer">
          <div
            className={cx(
              "customizer border-left-blue-grey border-left-lighten-4",
              isOpenSidebar ? "open" : "d-none d-md-block"
            )}
          >
            <div
              id="widget-52e71f7f6254c25b6801e462c69f42580ea008aa"
              style={{ display: !user ? "none" : "block" }}
              data-view="slide"
            />
            <div
              className="chatCloseIcon"
              onClick={() => dispatch(setSidebarState(!isOpenSidebar))}
            >
              <ClearIcon />
            </div>
            {!user && (
              // false
              <>
                {signUp === "signup" ? (
                  <SignUp setSignUp={setSignUp} />
                ) : signUp === "signin" ? (
                  <SignIn setSignUp={setSignUp} />
                ) : signUp === "forgot" ? (
                  <ForgotPassword setSignUp={setSignUp} />
                ) : (
                  ""
                )}
              </>
            )}
            {user && (
              // true
              <Menu setSignUp={setSignUp} />
            )}
          </div>
          <div className="sosAndGray">
            <div className="grayIcons">
              {containerArr.map((el, i) => {
                return (
                  <GrayIcon
                    img={el.img}
                    i={i}
                    // url={url}
                    // setUrl={setUrl}
                    key={i}
                    link={el.link}
                    title={el.title}
                  />
                );
              })}
            </div>
          </div>
          {/* <div
            className="mainChat"
            onClick={() => dispatch(setSidebarState(!isOpenSidebar))}
          >
            <img alt="" className="chat" src={chatIcon} />
            <div className="grayIconTitle white">צ'ט</div>
          </div> */}
          <div
            className="mainChat"
            onClick={() => {
              setSupport(true);
            }}
          // href="tel:089966241"
          >
            <BiSupport size={40} />
            <div className="grayIconTitle white">לנציג</div>
          </div>
        </footer>
      </div>
      <Drawer
        className="callOrChat"
        PaperProps={{
          sx: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "300px",
            bottom: "90px",
            height: "120px",
            margin: "auto",
            borderRadius: "60px",
            padding: "10px",
          },
        }}
        open={support}
        onClose={() => {
          setSupport(false);
        }}
        anchor="bottom"
      >
        <a
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            height: "100%",
            alignItems: "center",
            color: "black",
          }}
          href="tel:089966241"
        >
          <AiFillPhone fill="var(--primary)" size={50} />
          <h6>שיחה</h6>
        </a>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            height: "100%",
            alignItems: "center",
          }}
          onClick={() => {
            setSupport(false);
            widgetHelper.openMenu(() => dispatch(openSidebar()));
          }}
        // onClick={() => {            widgetHelper.openMenu(() => dispatch(openSidebar()))

        // dispatch(setSidebarState(!isOpenSidebar));
        // setSupport(false);
        // }}
        >
          <BsFillChatFill fill="var(--primary)" size={50} />
          <h6>צ'ט</h6>
        </div>
      </Drawer>
      {/* </MobileView> */}
    </>
  );
};

export default Footer;
