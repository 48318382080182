import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import MicIcon from "@mui/icons-material/Mic";
import MenuIcon from "@mui/icons-material/Menu";
import { GiTwoCoins } from "react-icons/gi";
// import InfoIcon from "@mui/icons-material/Info";
import PersonIcon from "@mui/icons-material/Person";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import Button from "@mui/material/Button";
import VideocamIcon from '@mui/icons-material/Videocam';
import SearchResult from "../searchResult/SearchResult";
import LanguageSelect from "../language/languageSelect";
import InstallPWA from "../InstallPWA";
import {
  getMerchants,
  getProductCategories,
  openSidebar,
} from "../../redux/actions-exporter";
import widgetHelper from "../../helpers/widget";
import Coupon from "../../assets/images/couponCover.jpg";
import clip from "../../assets/images/clip.mp4";
// import logo from "../../assets/images/win.png";
import logo from "../../assets/images/logoWide.png";
import logo2 from "../../assets/images/user3.jpg";
import whereAcceptElad from "../../assets/images/where_accept_elad.jpg";
import "./header.css";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const Header = () => {
  const [notification, setNotification] = useState(false);
  const [video, setVideo] = useState(false);
  const [filterProducts, setFilterProducts] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [searchText, setSearchText] = useState([]);
  const [filterStores, setFilterStores] = useState([]);
  const [stores, setStores] = useState([]);
  const [products, setProducts] = useState([]);
  const [iconHide, setIconHide] = useState(false);
  const merchants = useSelector((state) => state.merchant.merchants);

  const dispatch = useDispatch();
  const categories = useSelector(
    (state) => state.productCategories.productCategories
  );
  // useEffect(() => {
  //   dispatch(getMerchants(isWallet ? "load" : ""));
  //   dispatch(getProductCategories());
  // }, [dispatch]);

  const user = useSelector((state) => state.user.user);
  const handlenotification = () => {
    if (!notification) {
      setNotification(true);
    } else {
      setNotification(false);
    }
  };

  useEffect(() => {
    if (categories.length) {
      const prepareProduct = categories.reduce(
        (previous, current) => [
          ...previous,
          ...current.products.map((product) => ({
            ...product,
            categoryId: current.id,
            categoryName: current.title,
          })),
        ],
        []
      );
      setProducts(prepareProduct);
      const uniques = [
        ...new Map(prepareProduct.map((v) => [v.id, v])).values(),
      ];
      const onlyPrice = uniques.filter((product) => product.price > 0);
      setFilterProducts(onlyPrice);
    }
  }, [categories]);

  const openSearch = () => {
    setIsSearchOpen(true);
  };
  const openInfo = () => {
    setIsInfoOpen(true);
  };

  useEffect(() => {
    if (categories.length) {
      const prepareProduct = categories.reduce(
        (previous, current) => [
          ...previous,
          ...current.products.map((product) => ({
            ...product,
            categoryId: current.id,
            categoryName: current.title,
          })),
        ],
        []
      );
      setProducts(prepareProduct.filter((product) => product.active));

      setStores(
        merchants.filter(
          (merchant) => merchant.active && merchant.type !== "hr"
        )
      );
    }
  }, [categories, merchants]); // eslint-disable-line
  useEffect(() => {
    const filteredP = products.filter((product) =>
      product.title.includes(searchText)
    );
    filteredP === products
      ? setFilterProducts([])
      : setFilterProducts(filteredP);

    const filteredS = stores.filter(
      (store) =>
        store.title.includes(searchText) ||
        store.city?.includes(searchText) ||
        store.kosher?.includes(searchText)
    );

    filteredS === stores ? setFilterStores([]) : setFilterStores(filteredS);
  }, [searchText]); // eslint-disable-line
  // console.log(user);
  return (
    <div className="header">
      <div className="top">
        <div
          className="menuIcon"
          onClick={
            // () => widgetHelper.openMenu(
            () => dispatch(openSidebar())
            // )
          }
        >
          <MenuIcon fontSize="large" sx={{ color: "#fff" }} />
        </div>
        {/* <div
          className="menuIcon"
          onClick={() => {
            if (!user?.id) {
              widgetHelper.login(() => dispatch(openSidebar()));
            } else {
              widgetHelper.openProfile(() => dispatch(openSidebar()));
            }
          }}
        >
          <PersonIcon fontSize="large" sx={{ color: "var(--primary)" }} />
        </div> */}
        <div
          style={{
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            lineHeight: "16px",
          }}
        >
          <div>שלום,</div>
          <div>{user?.id ? user?.firstName : "אורח"}</div>
        </div>
        <Link className="logo" to="/">
          <img alt="" src={logo} width="200" />
        </Link>
        {/* <div className="menuIcon">
          <LanguageSelect />
        </div> */}
        <div className="menuIcon" onClick={openSearch}>
          <SearchOutlinedIcon
            fontSize="large"
            sx={{ color: "#fff" }}
          />
        </div>
      </div>
      {/* { */}
      {/* isFull ? */}
      <div className="bottom">
        {/* <div className="par">
          <div className="balanceH">
            ICLCoins
          </div>
          <div className="balanceAmount">
            <GiTwoCoins sx={{ color: "var(--primary)" }} />
            {user?.credit?.toFixed(2) || "0.00"}
          </div>
        </div> */}

        <div className="collect" onClick={openInfo}>
          {/* <InfoIcon /> */}
          <span>איפה מתפנקים</span>
          <GiTwoCoins fontSize="large" sx={{ color: "var(--primary)" }} />

          {/* <KeyboardArrowLeftIcon */}
          {/* sx={{ color: 'var(--primary)' }} */}
          {/* /> */}
        </div>
        {/* <div className="notificationBlock" style={{ marginLeft: 0 }}> */}
        {/* <Button className="dropBtn"> */}
        <InstallPWA icon={true} />
        {/* </Button> */}
        {/* </div> */}
        <div className="notificationBlock">
          <Button className="dropBtn">
            <VideocamIcon
            // onClick={() => setVideo(true)} 
            />
            {/* <NotificationsNoneIcon onClick={handlenotification} /> */}
            {/* <span className="subCount">1</span> */}
          </Button>
        </div>
        {notification ? (
          <div className="notificationList">
            <div className="chatMainBox">
              <div className="chatMainBox__inner">
                <div className="chatMainBox__img">
                  <img
                    // src={user}
                    src={logo2}
                    alt=""
                    height={50}
                    width={50}
                    className="img-fluid"
                  />
                </div>
                <div className="chatMainBox__info">
                  <h4 className="chatMainBox__name">מועדון ווינקרד</h4>
                  <p className="chatMainBox__text">שמחים לראותך כאן איתנו!</p>
                </div>
              </div>
              <span className="chatMainBox__time">4pm</span>
            </div>
          </div>
        ) : null}



        <Drawer
          PaperProps={{
            sx: {
              width: "80%",
              marginTop: "72px",
              maxWidth: "400px",
              position: "absolute",
            },
          }}
          open={isSearchOpen}
          onClose={() => setIsSearchOpen(false)}
        >
          <form className="search-container active-search">
            <div className="search-container__btn">
              <SearchOutlinedIcon />
            </div>
            <input
              type="text"
              id="search-bar"
              placeholder="חיפוש"
              className="search-container__input"
              onChange={(e) => setSearchText(e.target.value)}
              // onClick={openSearch}
              value={searchText}
            />
            <div className="mic-container__btn">
              <MicIcon />
            </div>
          </form>
          <SearchResult
            filterProducts={filterProducts}
            filterStores={filterStores}
          />
        </Drawer>
      </div>

      <Drawer
        className="infoWrapper"
        PaperProps={{
          sx: {
            width: "350px",
            left: "calc(50vw - 175px)",
            top: "12.5%",
            height: "75%",
            margin: "auto",
            borderRadius: "10px",
            padding: "10px",
          },
        }}
        open={isInfoOpen}
        onClose={() => setIsInfoOpen(false)}
      >
        <IconButton
          sx={{
            justifyContent: "end",
            p: 0,
          }}
          onClick={() => setIsInfoOpen(false)}
        >
          <CloseIcon />
        </IconButton>
        <div className="info">
          <img alt="" src={whereAcceptElad} className="bannersList" />
        </div>
      </Drawer>
      <Drawer
        PaperProps={{
          sx: {
            width: "96%",
            maxWidth: '420px',
            // left: "calc(50vw - 175px)",
            left: { xs: '2%', sm: "calc(50vw - 210px)" },
            top: "25%",
            height: "50%",
            margin: "auto",
            borderRadius: "10px",
            padding: "5px",
            display: 'flex',
            justifyContent: 'center'
          },
        }}
        open={video}
        onClose={() => setVideo(false)}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '5px',
            left: '5px',
            p: 0,
          }}
          onClick={() => setVideo(false)}
        >
          <CloseIcon />
        </IconButton>
        {/* <div className="info "> */}
        <video src={clip} width={'100%'} autoPlay />
        {/* </div> */}
      </Drawer>
    </div>
  );
};

export default Header;
