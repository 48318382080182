import { axiosNoTokenInstance } from "../../axios.config";

const sendUrlStatistics = (url) => {
  const _url = new URL(url);
  const source = _url.searchParams.get("source");

  if (source) {
    axiosNoTokenInstance.post("/statistic", { source, url }).catch(() => {
      console.log("Something went wrong with sending statistics");
    });
  }
};

export default sendUrlStatistics;
