import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Button } from "@material-ui/core";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import Header from "../header/Header";
import { getOrders, getUsage, getUnused } from "../../redux/actions-exporter";

import "../wallets/paymentHistory.css";

function PaymentHistory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const { orders } = useSelector((state) => state.order);
  const [list, setList] = useState([]);
  const [code, setCode] = useState([]);

  const used = useSelector((state) => state.order?.usage);
  const unused = useSelector((state) => state.order?.unused);
  // console.log("orders", orders);
  console.log("used", used);
  console.log("unused", unused);
  // console.log(unused);
  useEffect(() => {
    // dispatch(getOrders());
    dispatch(getUsage());
    dispatch(getUnused());
  }, [dispatch]); // eslint-disable-line

  function isObjEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  useEffect(() => {
    let newUsed =
      used.length > 0
        ? used
          .filter((item) => item.order.type === "load_credit")
          .map((item) => ({
            ...item,
            createdAt: new Date(item.order.createdAt),
          }))
        : [];
    let newUnused = !isObjEmpty(unused)
      ? unused
        ?.filter((item) => item.type === "load_credit")
        .map((item) => ({ ...item, createdAt: new Date(item.createdAt) }))
      : [];
    newUsed = newUsed?.concat(newUnused);
    newUsed = newUsed?.sort((a, b) => b.createdAt - a.createdAt);
    setList(newUsed);
  }, [used, unused]);

  return (
    <div>
      <Header isFull={true} />
      <div className="paymentHestoryTemplate">
        <div className="container">
          <div className="templateHeading">
            <span
              className="backIcon isLink"
              onClick={() => {
                navigate("/wallet");
              }}
            >
              <ArrowBackIcon />
            </span>
            <h1 className="templateTitle">{t("allp")}</h1>
          </div>
          <ul className="paymentHestoryCards">
            {list && list[0]
              ? list?.length > 0 &&
              list
                // .filter((item) => item.type === "load_credit")
                .map((order, i) => {
                  return (
                    <li className="cartProduct-Module__list" key={i}>
                      <div className="cartProduct-box">
                        <div className="cartProduct-box__info">
                          <h6 className="cartProduct-box__brand">
                            {/* {order.id} */}
                          </h6>
                          <br />

                          <h5 className="cartProduct-box__name">
                            {order.order ? "מומש" : "טרם מומש"}
                          </h5>
                          <h4 className="cartProduct-box__name">
                            {moment(order.createdAt)
                              .utcOffset(120)
                              .format("MM/DD/YY hh:mm")}
                          </h4>
                        </div>
                      </div>
                      <div className="cartProduct-rightBlock">
                        <div className="cartProduct-box__brandBox">
                          <div
                            className="cartProduct-box__price"
                            style={{ display: "block" }}
                          >
                            {order?.amount
                              ? order?.amount
                              : order?.order?.total}
                            ₪
                            <br />
                            <br />
                            {order.order ? (
                              <div className="cartProduct-box__metadata">
                                {order.supplierName}
                                <br />
                                {moment(order.usedDate)
                                  .utcOffset(120)
                                  .format("MM/DD/YY hh:mm")}
                              </div>
                            ) : (
                              <div className="cartProduct-box__metadata">
                                <Button
                                  className="addcart_btn"
                                  onClick={() => setCode([order])}
                                >
                                  <span>הצג</span>
                                </Button>
                                {/* קוד מולטיפס: {order.metadata.cardNumber}
                                  <br />
                                  {order.metadata.cardPin} */}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })
              : "אין היסטוריית תשלומים"}
          </ul>
        </div>
      </div>
      <Drawer
        className="infoWrapper"
        PaperProps={{
          sx: {
            width: "80%",
            left: "10%",
            top: "30%",
            height: "40%",
            margin: "auto",
            borderRadius: "10px",
            padding: "10px",
          },
        }}
        open={code.length > 0}
        onClose={() => setCode([])}
      >
        <IconButton
          sx={{
            justifyContent: "end",
            p: 0,
          }}
          onClick={() => setCode([])}
        >
          <CloseIcon />
        </IconButton>
        <div className="container">
          <div className="paymentApproveContent">
            <div className="paymentApproveText">
              <h5 className="pelecard-page__title">{code[0]?.amount} ₪</h5>
              <hr />
              <h5 className="paymentApproveLine1">
                קוד מולטיפס: {code[0]?.cardNumber}
              </h5>
              <h5 className="pelecard-page__info">(להצגה בקופה)</h5>
              {code[0]?.cardPin}
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default PaymentHistory;
