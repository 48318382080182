export const OrderActionTypes = {
  GET: {
    ORDER_PENDING: "GET_ORDER_PENDING",
    ORDER_SUCCESS: "GET_ORDER_SUCCESS",
    ORDER_ERROR: "GET_ORDER_ERROR",

    ORDERS_PENDING: "GET_ORDERS_PENDING",
    ORDERS_SUCCESS: "GET_ORDERS_SUCCESS",
    ORDERS_ERROR: "GET_ORDERS_ERROR",

    USAGE_PENDING: "GET_USAGE_PENDING",
    USAGE_SUCCESS: "GET_USAGE_SUCCESS",
    USAGE_ERROR: "GET_USAGE_ERROR",

    UNUSED_PENDING: "GET_UNUSED_PENDING",
    UNUSED_SUCCESS: "GET_UNUSED_SUCCESS",
    UNUSED_ERROR: "GET_UNUSED_ERROR",
  },
  CLEAR: {
    ORDER: "CLEAR_ORDER",
  },
};
