import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";

import { getMerchants } from "../../redux/actions-exporter";

// import hotBenefit1 from "../../assets/images/hotBenefit1.png";
import piza from "../../assets/images/piza.jpg";
import morning from "../../assets/images/morning.jpg";
import burger from "../../assets/images/burger.jpg";
import asian from "../../assets/images/asian.jpg";
const imgs = [piza, morning, asian, burger];
export default function HotBenefitList({
  isAllVendors,
  storesText,
  categories,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const hotBenefitsArr = categories
    ?.filter((benefit) => {
      if (
        benefit.title === "המבורגר" ||
        benefit.title === "ארוחה אסייתית" ||
        benefit.title === "ארוחות בוקר" ||
        benefit.title === "פיצה"
      ) {
        return benefit;
      }

      return false;
    })
    .reverse();

  // {
  //   title: "שוברי ארוחות בוקר",
  //   price: 34,
  //   image: hotBenefit2,
  //   category: "אוכל",
  //   coins: 10,
  //   city: "ירושלים",
  //   new: 24,
  //   id: 19,
  // },
  // {
  //   title: "שוברי פיצות",
  //   price: 49,
  //   image: hotBenefit1,
  //   category: "אוכל",
  //   coins: 20,
  //   city: "ירושלים",
  //   new: 29,
  //   id: 6,
  // },

  // {
  //   title: "שוברי המבורגרים",
  //   price: 11,
  //   image: hotBenefit3,
  //   category: "אוכל",
  //   coins: 4,
  //   city: "ירושלים",
  //   new: 7,
  //   id: 8,
  // },
  // {
  //   title: "שוברי אוכל אסייתי",
  //   price: 49,
  //   image: hotBenefit4,
  //   category: "אוכל",
  //   coins: 20,
  //   city: "ירושלים",
  //   new: 29,
  //   id: 13,
  // },
  // ];
  useEffect(() => {
    // dispatch(getMerchants());
  }, [dispatch]); // eslint-disable-line

  // const merchantCategoriesArr = [
  //   {
  //     title: t("ClothingAndFashionAccessories"),
  //     id: 1,
  //   },
  //   {
  //     title: t("footwear"),
  //     id: 2,
  //   },
  //   {
  //     title: t("HygieneCareAndBeauty"),
  //     id: 3,
  //   },
  //   {
  //     title: t("bookstoresAndSports"),
  //     id: 4,
  //   },
  //   {
  //     title: t("toysAndGames"),
  //     id: 5,
  //   },
  //   {
  //     title: t("foodAndCafes"),
  //     id: 6,
  //   },
  //   {
  //     title: t("housewares"),
  //     id: 7,
  //   },
  //   {
  //     title: t("electricalProductStores"),
  //     id: 8,
  //   },
  // ];

  return (
    <>
      <div className="row align-items-center">
        <div className="col-7">
          <h6 className="module-heading__title">ההטבות החמות</h6>
        </div>
        <div
          className="col-5 text-right isLink"
          onClick={() => {
            navigate("/allvendors");
          }}
        >
          {isAllVendors ? (
            <div className="module-heading__link">{t("allStores")}</div>
          ) : (
            ""
          )}
        </div>
      </div>

      {/* <Tabs
        defaultSelectedIndex={0}
        className="categoriesSliderTabs"
        // onChange={handleChange}
      >
        <Tab value="all" label={t("all")}>
          All
        </Tab>
        {merchantCategoriesArr.length > 0 &&
          merchantCategoriesArr.map((category) => (
            <Tab value={category.id} label={category.title}>
              {category.title}
            </Tab>
          ))}
      </Tabs> */}

      <ScrollingCarousel>
        <ul className="categoryList">
          {hotBenefitsArr.length > 0
            ? hotBenefitsArr.map((item, i) => (
                <>
                  <li
                    onClick={() => {
                      console.log(item.id);
                      navigate(`/category/${item.id}`, {
                        state: { id: item.id },
                      });
                    }}
                    className="hotBenefit isLink"
                  >
                    <div className="hotBenefitImg">
                      <img src={imgs[i]} alt="My Awesome" />
                      {/* <div className="hotBenefitCategory">{item.category}</div> */}
                    </div>
                    <div className="hotBenefitContent">
                      <div className="old">
                        <h6 className="hotBenefitNew">{item.title}</h6>
                      </div>
                    </div>
                  </li>
                </>
              ))
            : t("No merchants")}
        </ul>
      </ScrollingCarousel>
    </>
  );
}
