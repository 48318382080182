import { OrderActionTypes } from "./order.types";

const INITIAL_STATE = {
  isOrderPending: false,
  order: {},
  orderError: null,

  isOrdersPending: false,
  orders: {},
  ordersError: null,

  usage: {},
  isUsagePending: false,
  usageError: null,

  unused: {},
  isUnusedPending: false,
  unusedError: null,
};

const OrderReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case OrderActionTypes.GET.ORDER_PENDING:
      return {
        ...state,
        isOrderPending: true,
        order: {},
      };

    case OrderActionTypes.GET.ORDER_SUCCESS:
      return {
        ...state,
        isOrderPending: false,
        order: action.payload,
      };

    case OrderActionTypes.GET.ORDER_ERROR:
      return {
        ...state,
        isOrderPending: false,
        orderError: action.payload,
      };

    case OrderActionTypes.GET.ORDERS_PENDING:
      return {
        ...state,
        isOrdersPending: true,
        orders: {},
      };

    case OrderActionTypes.GET.ORDERS_SUCCESS:
      return {
        ...state,
        isOrdersPending: false,
        orders: action.payload,
      };

    case OrderActionTypes.GET.ORDERS_ERROR:
      return {
        ...state,
        isOrdersPending: false,
        ordersError: action.payload,
      };

    case OrderActionTypes.CLEAR.ORDER:
      return {
        ...state,
        order: {},
      };

    case OrderActionTypes.GET.USAGE_PENDING:
      return {
        ...state,
        isUsagePending: true,
      };

    case OrderActionTypes.GET.USAGE_SUCCESS:
      return {
        ...state,
        isUsagePending: false,
        usage: action.payload,
        usageError: null,
      };

    case OrderActionTypes.GET.USAGE_ERROR:
      return {
        ...state,
        isUsagePending: false,
        usageError: action.payload,
      };

    case OrderActionTypes.GET.UNUSED_PENDING:
      return {
        ...state,
        isUnusedPending: true,
      };

    case OrderActionTypes.GET.UNUSED_SUCCESS:
      return {
        ...state,
        isUnusedPending: false,
        unused: action.payload,
        unusedError: null,
      };

    case OrderActionTypes.GET.UNUSED_ERROR:
      return {
        ...state,
        isUnusedPending: false,
        unusedError: action.payload,
      };
    default:
      return state;
  }
};

export default OrderReducer;
