export const ProductCategoryActionsTypes = {
  GET: {
    GET_PRODUCT_CATEGORY_PENDING: "GET_PRODUCT_CATEGORY_PENDING",
    GET_PRODUCT_CATEGORY_SUCCESS: "GET_PRODUCT_CATEGORY_SUCCESS",
    GET_PRODUCT_CATEGORY_ERROR: "GET_PRODUCT_CATEGORY_ERROR",

    GET_PRODUCT_CATEGORY_BY_ID_PENDING: "GET_PRODUCT_CATEGORY_BY_ID_PENDING",
    GET_PRODUCT_CATEGORY_BY_ID_SUCCESS: "GET_PRODUCT_CATEGORY_BY_ID_SUCCESS",
    GET_PRODUCT_CATEGORY_BY_ID_ERROR: "GET_PRODUCT_CATEGORY_BY_ID_ERROR",

    GET_PRODUCTS_BY_CATEGORY_ID_PENDING: "GET_PRODUCTS_BY_CATEGORY_ID_PENDING",
    GET_PRODUCTS_BY_CATEGORY_ID_SUCCESS: "GET_PRODUCTS_BY_CATEGORY_ID_SUCCESS",
    GET_PRODUCTS_BY_CATEGORY_ID_ERROR: "GET_PRODUCTS_BY_CATEGORY_ID_ERROR",
  },
};
