import { SidebarActionTypes } from "./sidebar.types";

export const setSidebarState =
  (state = false) =>
  (dispatch) => {
    dispatch({ type: SidebarActionTypes.SET.STATE_SIDEBAR, payload: state });
  };

export const openSidebar = () => (dispatch) => {
  dispatch({ type: SidebarActionTypes.SET.OPEN_SIDEBAR });
};
