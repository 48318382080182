import { SidebarActionTypes } from "./sidebar.types";

const INITIAL_STATE = {
  isOpen: false,
};

const SidebarReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SidebarActionTypes.SET.STATE_SIDEBAR:
      return {
        ...state,
        isOpen: action.payload,
      };

    case SidebarActionTypes.SET.OPEN_SIDEBAR:
      return {
        ...state,
        isOpen: true,
      };

    default:
      return state;
  }
};

export default SidebarReducer;
