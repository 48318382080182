import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Header from "../header/Header";
import { getUser } from "../../redux/API/user/user.action";

import "./PaymentApprove.css";
import { clearOrder } from "../../redux/API/order/order.action";

// import qr from "../../assets/images/qr.jpg;

export default function PaymentApprove({ newMoney }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const order = useSelector((state) => state.order.order);

  useEffect(() => {
    dispatch(getUser());
  }, []); // eslint-disable-line

  useEffect(() => () => dispatch(clearOrder()), []); // eslint-disable-line

  return (
    <div>
      <Header />
      <div className="loadMoneyTemplate">
        <div className="container">
          <hr />
          <div className="templateHeading">
            <h1 className="templateTitle">{t("payment approve")}</h1>
          </div>
          <hr />
          <div className="paymentApproveContent">
            <div className="paymentApproveText">
              <h6 className="pelecard-page__info">
                סכום החשבון: {order?.total || 0} ₪
              </h6>
              <h5 className="pelecard-page__title">
                תשלום בסך {order?.total * order?.metadata?.discount || 0} ₪ בוצע
                בהצלחה
              </h5>
              <h5 className="paymentApproveLine1">
                {order?.metadata?.cardNumber ? (
                  <>קוד מולטיפס: {order.metadata.cardNumber}</>
                ) : (
                  <>{t("Loading card")}...</>
                )}
              </h5>
              <h5 className="pelecard-page__info">(להצגה בקופה)</h5>
              {order?.metadata?.cardPinPin ? (
                <>{order?.metadata?.cardPin}</>
              ) : (
                ""
              )}
            </div>
            <div className="loadMoneyCard-btn">
              <Button
                className="blueBtn"
                onClick={() => {
                  navigate("/");
                }}
              >
                {t("end")}
              </Button>
              <Button
                className="blueBtn gift"
                onClick={() => {
                  navigate("/");
                }}
              >
                שתף כמתנה
              </Button>
            </div>
          </div>
          {/* <div className="templateHeading">
            <h1 className="templateTitle">{t("payment approve")}</h1>
          </div>
          <div className="paymentApproveContent">
            <div className="paymentApproveText">
              <div className="paymentApproveLine1">
                {t("charged in")}₪{order.total}
              </div>
              <div className="paymentApproveLine2">
                {t("show to the waiter")}
              </div>
              <div className="paymentApproveLine3">
                {t("waiter, make a transaction")}
              </div>
            </div>
            {order?.metadata?.cardNumber && order?.metadata?.cardPin && (
              <div className="paymentApproveQr">
                {t("Card number")}: {order?.metadata?.cardNumber}
                <br />
                {t("Card pin")}: {order?.metadata?.cardPin}
              </div>
            )}
            <div className="paymentApproveCode">
              <div>
                {t("transaction code: ")}
                <strong>{order?.metadata?.id}</strong>
              </div>
            </div>
            <div className="loadMoneyCard-btn">
              <Button
                className="blueBtn"
                onClick={() => {
                  navigate("/");
                }}
              >
                {t("end")}
              </Button>
              <Button
                className="blueBtn gift"
                onClick={() => {
                  navigate("/");
                }}
              >
                שתף כמתנה
              </Button>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
