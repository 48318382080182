const autoReadSMS = (cb, delay) => {
  const ac = new AbortController();

  setTimeout(() => {
    ac.abort();
  }, delay * 1000);

  async function main() {
    if ("OTPCredential" in window) {
      try {
        if (navigator.credentials) {
          await navigator.credentials
            .get({
              otp: { transport: ["sms"] },
              signal: ac.signal,
            })
            .then((otp) => {
              if (otp && otp.code) {
                cb(otp.code);
              }
              ac.abort();
            })
            .catch((e) => {
              ac.abort();
            });
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
  main();
};

export default autoReadSMS;
