import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import widgetHelper from "../../helpers/widget";
import Header from "../header/Header";
import { getMerchants, openSidebar } from "../../redux/actions-exporter";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as Locate } from "../../assets/icons/locate.svg";

import "../vendor/vendorsDetails.css";
import { Padding } from "@mui/icons-material";

export default function AllVendor({ vendors }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const merchants = useSelector((state) => state.merchant.merchants);
  const merchantsPending = useSelector(
    (state) => state.merchant.isMerchantsPending
  );
  const [merchantsList, setMerchantsList] = useState([]);
  const [chain, setChain] = useState([]);
  const user = useSelector((state) => state.user.user);
  // const [filterMerchant, setFilterMerchant] = useState([]);

  // const handleChangeTab = (e, newValue) => {
  //   if (newValue === "all") {
  //     setMerchantsList(merchants);
  //     return setSelectedTab({ id: "all", category: null });
  //   }
  //   const category = categories.find(
  //     (category) => category.title === e.target.textContent
  //   );
  //   setSelectedTab({ id: newValue, category });
  //   const list = merchants?.find((merchant) =>
  //     merchant?.categories?.map((_category) => _category.id === category.id)
  //       ? merchant
  //       : null
  //   );
  //   setMerchantsList([list]);
  // };
  useEffect(() => {
    let list = [];
    merchants?.map((merchant) => {
      // if (!list.includes(merchant.region?.name)) 
      if (!list.includes(merchant.region?.name))
        list.push(merchant.region?.name)
    })
  }, []);
  useEffect(() => {
    setMerchantsList([]);
    // dispatch(getMerchants("load"));
  }, [dispatch]); // eslint-disable-line
  useEffect(() => {
    let list = [];
    if (vendors === "כל המסעדות") {
      merchants?.map((merchant) => {
        merchant.categories.map((category) => {
          if (
            category.id === 94 ||
            category.id === 97 ||
            category.id === 98 ||
            category.id === 99
          ) {
            if (
              !list.includes(merchant) &&
              !merchant.title.includes("סגור") &&
              !merchant.title.includes("איטלקיה")
            )
              list.push(merchant);
          }
        });
      });
      // const groups = Object.values(
      //   list.reduce((acc, curr) => {
      //     const firstWord = curr.title.split(" ")[curr.title.includes("קפה") || curr.title.includes("פיצה") ? 1 : 0];
      //     if (!acc[firstWord]) {
      //       acc[firstWord] = [];
      //     }

      //     acc[firstWord].push(curr);
      //     return acc;
      //   }, {})
      // );
      const groups = Object.values(
        list.reduce((acc, curr) => {
          const firstWord = curr.network?.name || curr.title;
          if (!acc[firstWord]) {
            acc[firstWord] = [];
          }

          acc[firstWord].push(curr);
          return acc;
        }, {})
      );
      setMerchantsList(groups.sort((a, b) => b.length - a.length));

      // let reg = [];
      // groups?.map((a) => {
      //   if (a.length === 1) reg.push(a[0].region?.name)
      // })
      // console.log(reg.sort());

    } else {
      merchants?.map((merchant) => {
        merchant.categories.map((category) => {
          if (
            category.id === 106 &&
            !merchant.title.includes("נגיסה") &&
            !merchant.title.includes("מקס") &&
            !merchant.title.includes("ESCAPE") &&
            !merchant.title.includes("עגבניה") &&
            !merchant.title.includes("לה בורסה")
          ) {
            list.push(merchant);
          }
        });
      });
      const popArr = [
        "Foot Locker",
        "FOX",
        "FOX home",
        "AMERICAN EAGLE",
        "GOLF",
        "GOLF&CO",
        "GOLF&KIDS",
        "H&O",
        "KITAN",
        "MASHBIR",
        "INTIMA",
        "BILLA BONG",
        "MANGO",
        "MAX MORETTI",
        "Timberland",
        "Nautica",
        "aerie",
        "מגה ספורט",
      ];
      list
        .sort((a, b) => (a.title < b.title ? 1 : -1))
        .sort((a) => (popArr.includes(a.title) ? -1 : 0));
      setMerchantsList(list.sort());
    }
  }, [merchants]); //eslint-disable-line

  console.log(merchantsList);

  return (
    <div>
      <Header isWallet={true} />

      <div className="pageTemplate">
        <div className="container">
          <div className="vendorAllListBlock">
            <h6 className="module-heading__title">{vendors}</h6>
            <ul className="categoryList">
              {merchantsList?.length > 0 ? (
                merchantsList.map((item) => (
                  <>
                    {vendors === "כל המסעדות" ? (
                      <li
                        className="categoryList__block"
                        onClick={() => {
                          // if (item.length === 1) {
                          if (item.length === 0) {
                            if (!user) {
                              widgetHelper.login(
                                () => dispatch(openSidebar()),
                                () => navigate("/login")
                              );
                            } else {
                              navigate("/businesspayment", {
                                state: { vendordetails: item[0] },
                              });
                            }
                          } else if (!chain.length) {
                            setChain(item)
                          }
                        }}
                      >
                        <div className="category-box__img">
                          <img
                            src={item[0].image}
                            className="img-fluid"
                            alt="My Awesome"
                          />
                        </div>
                        {/* {item.length > 1 ? ( */}
                        {item.length > 0 ? (
                          <>
                            <h6 className="category-box__title up">
                              {item[0].network?.name}

                            </h6>
                            {/* <h6
                              className="category-box__title down"
                              onClick={() => setChain(item)}
                            >
                              {item.length > 1 && (
                                <>
                                  סניפים
                                  <ArrowDropDownIcon />
                                </>
                              )}
                            </h6> */}
                          </>
                        ) : (
                          <h6 className="category-box__title">
                            {item[0].title.length < 20
                              ? item[0].title
                              : item[0].title.slice(0, 19) + "..."}
                          </h6>
                        )}


                        {chain === item ? (
                          <div className="chains">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                fontSize: "larger",
                                fontWeight: "bold",
                                padding: "10px 0",
                              }}
                            >
                              {chain.length === 1 ? "סניף" : "סניפי "}
                              {chain[0].network?.name || chain[0].title}
                              <IconButton
                                sx={{
                                  justifyContent: "end",
                                  p: 0,
                                }}
                                onClick={() => setChain([])}
                              >
                                <CloseIcon />
                              </IconButton>
                            </div>
                            <div
                              style={{
                                overflowY: "scroll",
                              }}
                            >
                              {chain?.map((branch, i) => (
                                <>
                                  {i !== 0 && (
                                    <hr
                                      style={{
                                        margin: "5px",
                                        borderColor: "#eee",
                                      }}
                                    />
                                  )}
                                  <div
                                    // className="chain"
                                    className="menuSearchItem"
                                    onClick={() => {
                                      if (!user) {
                                        widgetHelper.login(
                                          () => dispatch(openSidebar()),
                                          () => navigate("/login")
                                        );
                                      } else {
                                        navigate("/businesspayment", {
                                          state: { vendordetails: branch },
                                        });
                                      }
                                    }}
                                  >
                                    <img alt="" className="menuSearchItemImg" src={branch.image} />
                                    <div className="menuSearchItemText">
                                      <div                 >
                                        {branch.title}
                                      </div>
                                      <div
                                      >
                                        {branch.address && (
                                          <div className="menuSearchItemText_sub">
                                            <Locate />
                                            {branch.address}
                                          </div>
                                        )}

                                        {branch.kosher === "כשר" ||
                                          (branch.kosher === "כשר למהדרין" && (
                                            <div className="menuSearchItemText_sub">
                                              {branch.kosher}
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                        ) : null}
                      </li >
                    ) : (
                      <li
                        className="categoryList__block"
                        onClick={() => {
                          if (!user) {
                            widgetHelper.login(
                              () => dispatch(openSidebar()),
                              () => navigate("/login")
                            );
                          } else {
                            navigate("/businesspayment", {
                              state: { vendordetails: item },
                            });
                          }
                        }}
                      >
                        <div className="category-box__img">
                          <img
                            src={item.image}
                            className="img-fluid"
                            alt="My Awesome"
                          />
                        </div>
                        <h6 className="category-box__title">
                          {item.title.length < 30
                            ? item.title
                            : item.title.slice(0, 29) + "..."}
                        </h6>
                      </li>
                    )}
                  </>
                ))
              ) : merchantsPending ? (
                <CircularProgress />
              ) : (
                // t("No merchants")
                ""
              )}
            </ul>
          </div>
        </div>
      </div>
    </div >
  );
}
