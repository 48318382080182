import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";

import Header from "../header/Header";
import {
  fastCheckout,
  getCards,
  addCard,
  removeCard,
} from "../../redux/actions-exporter";

import "../cart/checkout.css";
// import Visa from "../../assets/images/visa.jpg";
// import MasterCard from "../../assets/images/mastercard.png";
import Debitcard from "../../assets/images/debitcard1.png";

function Checkout() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const [card, setCard] = useState({});
  const [showaddress, setShowAddress] = useState(false);
  const [showcard, setShowCard] = useState(false);
  const cards = useSelector((state) => state.card.cards);
  const [paymentMethod, setPaymentMethod] = useState("pelecard-iframe");
  console.log(cards[0]?.id);
  useEffect(() => {
    dispatch(getCards());
  }, []); // eslint-disable-line
  useEffect(() => {
    cards.length && setPaymentMethod(cards[0]?.id)
  }, [cards]); // eslint-disable-line
  const onCheckout = () => {
    setCard({});
    dispatch(
      fastCheckout(
        {
          ...location?.state,
          cardId: paymentMethod === "pelecard-iframe" ? null : paymentMethod,
        },
        (data) => {
          const { orderId } = data;
          if (paymentMethod === "pelecard-iframe") {
            const { paymentURL } = data;
            navigate("/pay", {
              state: { paymentURL, orderId },
            });
          } else {
            navigate(
              `/checkout/${data?.isPaid ? "success" : "error"}/${orderId}`,
              { state: { error: data?.message || "" } }
            );
          }
        }
      )
    );
  };

  return (
    <div>
      <Header />
      <div className="pageTemplate">
        <div className="container">
          {!showaddress && !showcard ? (
            <div>
              <div className="templateHeading">
                <span className="backIcon isLink">
                  <ArrowBackIcon
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                </span>
                <h1 className="templateTitle">{t("checkout")}</h1>
              </div>
              {/* <div className="checkoutBlocks">
                <h5 className="checkoutBlocks-title">{t("deliveryAddress")}</h5>
                <div className="row align-items-center">
                  <div className="col-lg-4">
                    <TextField
                      id="outlined-basic"
                      label={t("homeAddress")}
                      variant="outlined"
                      placeholder={t("homeAddress")}
                    />
                  </div>
                  <div className="col-lg-12 text-center">
                    <Button
                      variant="text"
                      onClick={() => setShowAddress(true)}
                      className="LinkBtn"
                    >
                      <AddIcon /> {t("newAddress")}
                    </Button>
                  </div>
                </div>
              </div> */}
              <div className="checkoutBlocks">
                <h5 className="checkoutBlocks-title">
                  {" "}
                  {t("choosePaymentMethod")}
                </h5>
                <div className="row justify-content-center">
                  {cards.map((card) => (
                    <div
                      className="col-lg-4"
                      onClick={() => setPaymentMethod(card.id)}
                    >
                      <div
                        className={cx("PaymentCards", {
                          PaymentCards__selected: paymentMethod === card.id,
                        })}
                      >
                        {/*<div className="PaymentCards-img">*/}
                        {/*  <img*/}
                        {/*    src={Visa}*/}
                        {/*    alt=""*/}
                        {/*    height={100}*/}
                        {/*    width={100}*/}
                        {/*    className="img-fluid"*/}
                        {/*  />*/}
                        {/*</div>*/}
                        <span className="PaymentCards-no">{card.number}</span>
                        <DeleteIcon
                          className="PaymentCards__delete"
                          onClick={() => dispatch(removeCard(card.id))}
                        />
                      </div>
                    </div>
                  ))}
                  <div
                    className="col-lg-4"
                    onClick={() => setPaymentMethod("pelecard-iframe")}
                  >
                    <div
                      className={cx("PaymentCards", {
                        PaymentCards__selected:
                          paymentMethod === "pelecard-iframe",
                      })}
                    >
                      <span className="PaymentCards-no">
                        {t("Pelecard (iframe)")}
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-12 text-center">
                    <Button
                      variant="text"
                      onClick={() => setShowCard(true)}
                      className="LinkBtn"
                    >
                      <AddIcon />
                      {t("addNewCard")}
                    </Button>
                  </div>
                </div>
              </div>
              {/*<div className="checkoutBlocks">*/}
              {/*  <h5 className="checkoutBlocks-title">{t("payWithBlance")}:</h5>*/}
              {/*  <p className="text-center">{t("totalEcredits")}:147</p>*/}
              {/*</div>*/}
              {/*<div className="checkoutBlocks">*/}
              {/*  <h5 className="checkoutBlocks-title">{t("payWithBlance")}:</h5>*/}
              {/*  <div className="text-center">*/}
              {/*    <Button variant="text" className="LinkBtn">*/}
              {/*      <AddIcon />*/}
              {/*      {t("showAllBonuses")}*/}
              {/*    </Button>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="checkoutFooterBtn">
                <Button variant="outlined" onClick={onCheckout}>
                  {t("payNow")}
                </Button>
              </div>
            </div>
          ) : (
            <>
              {showaddress ? (
                <div>
                  <div className="templateHeading">
                    <span className="backIcon">
                      <ArrowBackIcon onClick={() => setShowAddress(false)} />
                    </span>
                    <h1 className="templateTitle"> {t("newAddress")}</h1>
                  </div>
                  <div className="checkoutBlocks">
                    <h5 className="checkoutBlocks-title text-center">
                      {t("deliveryAddress")}
                    </h5>
                    <div className="row">
                      <div className="col-lg-6">
                        <TextField
                          id="outlined-basic"
                          label={t("country")}
                          variant="outlined"
                        />
                      </div>
                      <div className="col-lg-6">
                        <TextField
                          id="outlined-basic"
                          label={t("city")}
                          variant="outlined"
                        />
                      </div>
                      <div className="col-lg-6">
                        <TextField
                          id="outlined-basic"
                          label={t("zipcode")}
                          variant="outlined"
                        />
                      </div>
                      <div className="col-lg-6">
                        <TextField
                          id="outlined-basic"
                          label={t("street")}
                          variant="outlined"
                        />
                      </div>
                      <div className="col-lg-6">
                        <TextField
                          id="outlined-basic"
                          label={t("appartment")}
                          variant="outlined"
                        />
                      </div>
                      <div className="col-lg-6">
                        <TextField
                          id="outlined-basic"
                          label={t("phone")}
                          variant="outlined"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="checkoutFooterBtn">
                    <Button
                      variant="outlined"
                      onClick={() => setShowAddress(false)}
                    >
                      {t("save")}
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="templateHeading">
                    <span className="backIcon">
                      <ArrowBackIcon onClick={() => setShowCard(false)} />
                    </span>
                    <h1 className="templateTitle">{t("addNewCard")}</h1>
                  </div>
                  <div className="checkoutBlocks">
                    <div className="checkoutBlocks-mainImg text-center">
                      <img
                        src={Debitcard}
                        alt=""
                        height={500}
                        width={500}
                        className="img-fluid"
                      />
                    </div>
                    <h5 className="checkoutBlocks-title text-center">
                      {t("paymentMethod")}
                    </h5>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="cardTextNo">
                          <TextField
                            id="outlined-basic"
                            label={t("cardNumber")}
                            variant="outlined"
                            onChange={(e) =>
                              setCard({
                                ...card,
                                number: e.currentTarget.value,
                              })
                            }
                          />
                          {/*<img*/}
                          {/*  src={MasterCard}*/}
                          {/*  alt=""*/}
                          {/*  height={100}*/}
                          {/*  width={100}*/}
                          {/*  className="img-fluid"*/}
                          {/*/>*/}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-6">
                            <TextField
                              id="outlined-basic"
                              label={t("expiryDate")}
                              variant="outlined"
                              onChange={(e) =>
                                setCard({
                                  ...card,
                                  expirationDate: e.currentTarget.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-6">
                            <TextField
                              id="outlined-basic"
                              label={t("CVC/CVV")}
                              variant="outlined"
                              onChange={(e) =>
                                setCard({
                                  ...card,
                                  cvv2: e.currentTarget.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <TextField
                          id="outlined-basic"
                          label={t("cardName")}
                          variant="outlined"
                          onChange={(e) =>
                            setCard({ ...card, name: e.currentTarget.value })
                          }
                        />
                      </div>
                      <div className="col-lg-12">
                        <TextField
                          id="outlined-basic"
                          label={t("id")}
                          variant="outlined"
                          onChange={(e) =>
                            setCard({ ...card, idCard: e.currentTarget.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="checkoutFooterBtn">
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setShowCard(false);
                          setCard({});
                          dispatch(addCard(card));
                        }}
                      >
                        {t("save")}
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Checkout;
