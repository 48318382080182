import React from "react";

function ReferralProgram() {
  return (
    <div>
      <div className="balanceBox">
        <div className="balanceBoxInner bg-light">
          <h2 className="balanceBoxTitle text-center text-dark">
            Referral program
          </h2>
        </div>
      </div>
    </div>
  );
}

export default ReferralProgram;
